import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ANALYTICS_URL } from '../shared/constants/analytics.consts';
import { UpdateConversationType } from '../shared/models/web-component-config.model';
import { IStandardEventLog } from '../shared/models/analytics.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private readonly measurementId = environment.googleAnalytics.measurementId;
  private readonly analyticsEnabled = environment.googleAnalytics.enabled;
  readonly EXCLUDED_GA_EVENTS: UpdateConversationType[] = ['FIRST_MESSAGE_COMPLETE', 'MESSAGE_SENT', 'MESSAGE_RECEIVED'];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  appendScripts() {
    if (this.analyticsEnabled) {
      this.createMainTagScript();
      this.createScriptTag();
    } else {
      this.removeScriptTags();
      console.warn("NOT IN PRODUCTION. DON'T APPEND");
    }
  }

  private removeScriptTags() {
    setTimeout(() => {
      this.document.getElementById('google-analytics')?.remove();
      const mainScript = this.document.querySelector(`script[src*='googletagmanager']`);
      if (mainScript) {
        mainScript.innerHTML = '';
        mainScript.remove();
      }
    }, 1000);
  }

  private createMainTagScript() {
    const script = this.document.createElement('script');
    script.async = true;
    script.src = `${ANALYTICS_URL}${this.measurementId}`;
    script.id = 'google-analytics-main';

    this.document.head.append(script);
  }

  private createScriptTag() {
    const script = this.document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.id = 'google-analytics';

    script.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());

    gtag('config', '${this.measurementId}', {'debug_mode': false});
    `;

    this.document.head.append(script);
  }

  logEvent(event: IStandardEventLog) {
    const payload = typeof event.payload === 'string' ? event.payload : JSON.stringify(event.payload);
    gtag('event', event.type, {
      assistantId: event.assistantId || null,
      payload,
    });
  }
}
